.ptitle{
    color:#999;
    text-align: center;
    line-height: 1rem;
    font-size: 0.25rem;
}

.btn{
    position: fixed;
    bottom: 0;
    left: 0;
}
@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;