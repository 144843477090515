.ptitle {
  color: #999;
  text-align: center;
  line-height: 1rem;
  font-size: 0.25rem;
}
.btn {
  position: fixed;
  bottom: 0;
  left: 0;
}
